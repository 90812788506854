import React from 'react'
import { Link } from 'gatsby'

import netlify from '../../content/images/netlify.png'
import gatsby from '../../content/thumbnails/gatsby.png'
import github from '../../content/images/github.png'
import instagram from '../../content/images/instagram.png'
import linkedin from '../../content/images/linkedin.png'

export default function Footer() {
  return (
    <footer className="footer flex">
      <section className="container">
        <nav className="footer-links">
          <a
            href="https://gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Built using Gatsby JS - 2021
          </a>
        </nav>
        <nav className="flex justify-center">
          <a
            href="https://www.linkedin.com/in/jasyohuang/"
            title="Find me on LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
            className="img"
          >
            <img src={linkedin} className="footer-img" alt="linkedin" />
          </a>
          <a
            href="https://github.com/jasyohuang"
            title="Find me on Github"
            target="_blank"
            rel="noopener noreferrer"
            className="img"
          >
            <img src={github} className="footer-img" alt="GitHub" />
          </a>
          <a
            href="https://instagram.com/jasyohuang"
            title="Find me on Instagram"
            target="_blank"
            rel="noopener noreferrer"
            className="img"
          >
            <img src={instagram} className="footer-img" alt="instagram" />
          </a>
          <a
            href="https://Gatsbyjs.org"
            title="Powered by GatsbyJS"
            target="_blank"
            rel="noopener noreferrer"
            className="img"
          >
            <img src={gatsby} className="footer-img" alt="gatsby" />
          </a>
          <a
            href="https://netlify.com"
            title="Hosted on Netlify"
            target="_blank"
            rel="noopener noreferrer"
            className="img"
          >
            <img src={netlify} className="footer-img" alt="netlify" />
          </a>
        </nav>
      </section>
    </footer>
  )
}
